import React, { useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { useFinchConnect } from 'react-finch-connect';

import { Box, Button, Heading, Flex, Link, Text, Icon } from '@chakra-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import pie_insurance from './pie_insurance.jpg';

import './App.css';

const clientId = process.env.REACT_APP_FINCH_CLIENT_ID;

const employeeHeaders = [
  {
    name: 'Company Name',
    key: 'company.name',
  },
  {
    name: 'Title',
    key: 'title',
  },
  {
    name: 'Employment Type',
    key: 'type',
  },
];

const directoryHeaders = [
  {
    name: 'First Name',
    key: 'first_name',
  },
  {
    name: 'Last Name',
    key: 'last_name',
  },
  {
    name: 'Title',
    key: 'title',
  },
  {
    name: 'Department',
    key: 'department.name',
  },
];

const companyHeaders = [
  {
    name: 'Name',
    key: 'legal_name',
  },
  {
    name: 'EIN',
    key: 'ein',
  },
  {
    name: 'Contact Email',
    key: 'primary_email',
  },
];

const App = () => {
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [directory, setDirectory] = useState(null);
  const [connected, setConnected] = useState(false);

  const onSuccess = async ({ code }) => {
    setConnected(true);
    const res = await axios.post(`https://lendtable-api.tryfinch.com/callback`, {
      code,
      state: 'pie_insurance',
    });
    if (res.status === 200) {
      const { data } = res;
      if (Array.isArray(data)) {
        setHeaders(employeeHeaders);
        setRows(data);
      } else if (Object.keys(data).includes('company') && Object.keys(data).includes('directory')) {
        setHeaders(companyHeaders);
        setRows([data.company]);
        setDirectory(_.get(data, 'directory.individuals', null));
      } else {
        setHeaders(companyHeaders);
        setRows([data]);
      }
    }
  };
  const onError = ({ errorMessage }) => console.error(errorMessage);
  const onClose = () => console.log('User exited Finch Connect');

  const { open: openEmployer } = useFinchConnect({
    clientId,
    products: ['company', 'directory', 'individual', 'employment', 'payment', 'pay_statement'],
    mode: 'employer',
    onSuccess,
    onError,
    onClose,
  });

  console.log(connected);

  return (
    <div>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Heading mb={8} mt={8}>
          <img src={pie_insurance} style={{ height: '70px' }} />
        </Heading>
        {!connected ? (
          <>
            <Text ml={4} mr={4} fontSize="xl">
              Connect your payroll system with Pie Insurance
            </Text>
            <Button
              size="lg"
              mt="24px"
              onClick={() => openEmployer()}
              style={{ backgroundColor: '#a0318c', color: 'white', marginBottom: '32px' }}
            >
              Connect
            </Button>
            <Text ml={4} mr={4} fontSize="xs">
              Questions? Contact us at{' '}
              <Link color="#ff9619" href="mailto:audit@pieinsurance.com">
                audit@pieinsurance.com
              </Link>{' '}
              or{' '}
              <Link color="#ff9619" href="tel:8558800204">
                855-880-0204
              </Link>{' '}
              (M-F 9am - 9pm ET){' '}
            </Text>
          </>
        ) : (
          <div style={{ maxWidth: '700px' }}>
            <Text ml={4} mr={4} fontSize="xl" style={{ marginBottom: '32px' }}>
              Your payroll account has been successfully connected. Next steps:
            </Text>
            <div style={{ marginLeft: '32px' }}>
              <Text ml={4} mr={4} fontSize="l" style={{ marginBottom: '16px' }}>
                <b>1.</b> Return to{' '}
                <Link color="#ff9619" href="https://premiumaudit.pieinsurance.com">
                  premiumaudit.pieinsurance.com
                </Link>{' '}
                to complete your audit questionnaire (don't worry, we'll pull the payroll documents
                for you)
              </Text>
              <Text ml={4} mr={4} fontSize="l" style={{ marginBottom: '16px' }}>
                <b>2.</b> Your auditor will be in touch with you soon.
              </Text>
              <Text ml={4} mr={4} fontSize="l" style={{ marginBottom: '32px' }}>
                <b>3.</b> Pie will disconnect from your payroll system once your audit has been
                completed.
              </Text>
            </div>
            <Text ml={4} mr={4} fontSize="xs" style={{ textAlign: 'center' }}>
              Questions? Contact us at{' '}
              <Link color="#ff9619" href="mailto:audit@pieinsurance.com">
                audit@pieinsurance.com
              </Link>{' '}
              or{' '}
              <Link color="#ff9619" href="tel:8558800204">
                855-880-0204
              </Link>{' '}
              (M-F 9am - 9pm ET){' '}
            </Text>
          </div>
        )}
      </Flex>
    </div>
  );
};

export default App;
